:root {
  --color-text-contrast: #212428;
  --color-text-gray: #737373;
  --color-dark-elements-gray: #98a4ab;
  --color-light-gray: #cad5db;
  --color-background-gray: #f7f7f7;
  --color-template-gray: #f0f0f0;
  --color-template-highlight: #fff;
  --color-blue-white: #f5f8fa;
  --color-airy-dark-blue: #1b4469;
  --color-airy-logo-blue: #4bb3fd;
  --color-hover-blue: #337bb3;
  --color-airy-blue: #1578d4;
  --color-airy-accent: #1578d4;
  --color-airy-blue-hover: #1e62d0;
  --color-airy-blue-pressed: #1b4469;
  --color-fb-cta: #1877f2;
  --color-fb-cta-border: #2281fd;
  --color-background-blue: #f1faff;
  --color-red-alert: #d51548;
  --color-accent-magenta: #f7147d;
  --color-error-background: #fae6e9;
  --color-highlight-yellow: #fbbd54;
  --color-background-yellow: #fbf9ee;
  --color-background-red: #fff7f9;
  --color-soft-green: #0da36b;
  --color-tag-green: #0e764f;
  --color-tag-purple: #730a80;
}
