@import '../../assets/scss/fonts.scss';

.container {
  margin-top: 88px;
  padding: 24px 24px 0 24px;
}

.infoContainer {
  text-align: left;
  h1 {
    font-weight: 400;
    margin: 0 0 0 0;
  }
  h2 {
    font-weight: 400;
    font-size: 20px;
    color: var(--color-text-gray);
    margin: 8px 0 0 0;
  }
}

.customizeContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-flow: wrap;
}

.sectionTitle {
  font-weight: 400;
  font-size: 16px;
  margin: 32px 0 8px 0;
}

.inputsContainer {
  margin-right: 16px;
  text-align: left;
  max-width: 250px;
  p {
    @include font-base;
    color: var(--color-text-gray);
    margin: 0;
  }
  div {
    color: var(--color-text-gray);
  }
}

.colorPickerWrapper {
  position: absolute;
  padding: 46px 24px 46px 0px;
}

.colorPicker {
  display: flex;
  place-items: flex-end;
}

.colorPickerSample {
  align-self: center;
  background-color: var(--color-airy-blue);
  box-shadow: lightgray 0px 0px 3px 0px;
  height: 34px;
  width: 40px;
  border-radius: 20%;
  margin-right: 4px;
}

.mediaButtonsContainer {
  display: flex;
  flex-direction: column;
  width: 200px;
}

.installButton {
  margin: 8px 0 8px 0;
  button {
    width: 100%;
    font-size: 16px;
  }
}

.repoButton {
  @extend .installButton;
  button {
    height: 50px;
  }
}

.pluginWrapper {
  position: fixed;
  bottom: 0;
  right: 0;
  height: 700px;
  width: 450px;
}
