.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
  margin-right: 8px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-background-blue);
  transition: 0.4s;
  border-radius: 24px;
  border: 1px solid var(--color-airy-blue);
}

.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 3px;
  background-color: var(--color-airy-blue);
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: var(--color-airy-blue);
}

input:focus + .slider {
  box-shadow: 0 0 0 2pt Highlight;
}
@media (-webkit-min-device-pixel-ratio: 0) {
  input:focus + .slider {
    box-shadow: 0 0 0 2pt -webkit-focus-ring-color;
  }
}

input:checked + .slider:before {
  transform: translateX(24px);
  background-color: white;
}
