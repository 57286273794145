@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900');

@mixin font-s {
  font-family: 'Lato', sans-serif;
  font-size: 0.8rem; // 13px
  line-height: 1rem;
}

@mixin font-base {
  font-family: 'Lato', sans-serif;
  font-size: 1rem; // 16 px
  line-height: 1.5rem;
}

@mixin font-m {
  font-family: 'Lato', sans-serif;
  font-size: 1.25rem; // 20px
  line-height: 2rem;
}

@mixin font-l {
  font-family: 'Lato', sans-serif;
  font-size: 1.563rem; // 25px
  line-height: 2rem;
}

@mixin font-xl {
  font-family: 'Lato', sans-serif;
  font-size: 1.953rem; // 31px
  line-height: 2.5rem;
}

@mixin font-xxl {
  font-family: 'Lato', sans-serif;
  font-size: 2.441rem; // 39px
  line-height: 3rem;
}

@mixin font-xxxl {
  font-family: 'Lato', sans-serif;
  font-size: 3.052rem; // 48px
  line-height: 3.5rem;
}

@mixin font-4l {
  font-family: 'Lato', sans-serif;
  font-size: 3.815rem; // 61px
  line-height: 4rem;
}

@mixin font-5l {
  font-family: 'Lato', sans-serif;
  font-size: 4.815rem; // 76px
  line-height: 4.5rem;
}

.font-s {
  @include font-s;
}

.font-base {
  @include font-base;
}

.font-m {
  @include font-m;
}

.font-l {
  @include font-l;
}

.font-xl {
  @include font-xl;
}

.font-xl {
  @include font-xl;
}

.font-xxl {
  @include font-xxl;
}

.font-xxxl {
  @include font-xxxl;
}

.font-4l {
  @include font-4l;
}

.font-5l {
  @include font-5l;
}
